import { lazy, Suspense } from 'react'
import {
  createBrowserRouter,
  LoaderFunctionArgs,
  Navigate,
  Outlet,
  redirect,
} from 'react-router-dom'

import { RoutePaths } from '@/enums'

import { createDeepPath } from './helpers'
import MainLayout from './layouts/MainLayout'
import { authStore } from './store'

export function createRouter() {
  const SignIn = lazy(() => import('@/pages/SignIn'))
  const Markets = lazy(() => import('@/pages/Markets'))
  const Badges = lazy(() => import('@/pages/Badges'))
  const Users = lazy(() => import('src/pages/Users'))

  const signInGuard = ({ request }: LoaderFunctionArgs) => {
    const requestUrl = new URL(request.url)
    const from = requestUrl.searchParams.get('from')

    return authStore.accessToken
      ? redirect(from ? `${from}${requestUrl.search}` : RoutePaths.Root)
      : null
  }

  const authProtectedGuard = ({ request }: LoaderFunctionArgs) => {
    // If the user is not logged in and tries to access protected route, we redirect
    // them to sign in with a `from` parameter that allows login to redirect back
    // to this page upon successful authentication
    if (!authStore.accessToken) {
      const requestUrl = new URL(request.url)
      requestUrl.searchParams.set('from', requestUrl.pathname)

      return redirect(`${RoutePaths.SignIn}${requestUrl.search}`)
    }

    return null
  }

  return createBrowserRouter([
    {
      path: RoutePaths.Root,
      element: (
        <Suspense fallback={<></>}>
          <Outlet />
        </Suspense>
      ),
      children: [
        {
          path: RoutePaths.Root,
          element: <Navigate replace to={RoutePaths.Markets} />,
        },
        {
          path: createDeepPath(RoutePaths.SignIn),
          loader: signInGuard,
          element: <SignIn />,
        },
        {
          loader: authProtectedGuard,
          element: (
            <MainLayout>
              <Suspense fallback={<></>}>
                <Outlet />
              </Suspense>
            </MainLayout>
          ),
          children: [
            {
              path: createDeepPath(RoutePaths.Markets),
              element: <Markets />,
            },
            {
              path: createDeepPath(RoutePaths.Badges),
              element: <Badges />,
            },
            {
              path: createDeepPath(RoutePaths.Users),
              element: <Users />,
            },
          ],
        },
        {
          path: '*',
          element: <Navigate replace to={RoutePaths.Root} />,
        },
      ],
    },
  ])
}
