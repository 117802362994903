export enum RoutePaths {
  Root = '/',
  SignIn = '/sign-in',

  Markets = '/markets',
  RegularMarkets = '/markets/regular',
  RegularMarketsNew = '/markets/regular/new',
  RegularMarketsEdit = '/markets/regular/:id/edit',
  ExchangeMarkets = '/markets/exchange',
  ExchangeMarketsNew = '/markets/exchange/new',
  ExchangeMarketsEdit = '/markets/exchange/:id/edit',

  Badges = '/badges',

  Users = '/users',
}
